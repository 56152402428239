import classNames from 'classnames/bind';
import styles from './Home.module.scss';
import Mission from '../../components/Mission';

import Slider from '../../components/Slider';
import { Link } from 'react-router-dom';
import ListHome from './ListHome';
import { useEffect, useState } from 'react';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import './Home.css';
import avtIcon from '../../assets/images/avt-icon.png';

import viLoiNhuan from '../../assets/images/viloinhuan.png';
import napNhanh from '../../assets/images/napnhanh.png';
import rutNhanh from '../../assets/images/rutnhanh.png';

import hopQua from '../../assets/images/vong-quay-may-man.png';
import bacDoan from '../../assets/images/tvbac.png';
import vangDoan from '../../assets/images/tvvang.png';
import bachKim from '../../assets/images/tvbachkim.jpg';
import kimCuong from '../../assets/images/tvKimcuong.jpg';
import bgMission from '../../assets/images/bg-mission.png';
import centralGroupImage from '../../assets/images/central-group1.01f652cf606b90b29885.png';
import khoa from '../../assets/images/khoa.png';
import vechungta from '../../assets/images/vechungta.png';
import mota from '../../assets/images/mota.png';
import taichinh from '../../assets/images/taichinh.png';
import vanhoadoanhnghiep from '../../assets/images/vanhoadoanhnghiep.png';
import dieukien from '../../assets/images/dieukien.png';
import mauthuthap from '../../assets/images/mauthuthap.png';
import phanchianhom from '../../assets/images/phanchianhom.png';
import chinhsachcanhan from '../../assets/images/chinhsachcanhan.png';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import modalTitleLeft from '../../assets/images/model-title-left.png';
import modalTitleRight from '../../assets/images/modal-title-right.png';
import thongbaodacbiet from '../../assets/images/thongbaodacbiet.png';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';

import lv1_1 from '../../assets/images/level/lv1_1.jpg';
import lv1_2 from '../../assets/images/level/lv1_2.jpg';
import lv1_3 from '../../assets/images/level/lv1_3.jpg';
import lv1_4 from '../../assets/images/level/lv1_4.jpg';
import lv2_1 from '../../assets/images/level/lv2_1.jpg';
import lv2_2 from '../../assets/images/level/lv2_2.jpg';
import lv2_3 from '../../assets/images/level/lv2_3.jpg';
import lv2_4 from '../../assets/images/level/lv2_4.jpg';
import lv3_1 from '../../assets/images/level/lv3_1.jpg';
import lv3_2 from '../../assets/images/level/lv3_2.jpg';
import lv3_3 from '../../assets/images/level/lv3_3.jpg';
import lv3_4 from '../../assets/images/level/lv3_4.jpg';
import lv4_1 from '../../assets/images/level/lv4_1.jpg';
import lv4_2 from '../../assets/images/level/lv4_2.jpg';
import lv4_3 from '../../assets/images/level/lv4_3.jpg';
import lv4_4 from '../../assets/images/level/lv4_4.jpg';

const axios = require('axios').default;

const imgLv = {
    vip6: [lv1_1, lv1_2, lv1_3, lv1_4],
    vip5: [lv2_1, lv2_2, lv2_3, lv2_4],
    vip4: [lv3_1, lv3_2, lv3_3, lv3_4],
    vip3: [lv4_1, lv4_2, lv4_3, lv4_4],
};

const cx = classNames.bind(styles);

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}
const formatter = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
});

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function Home(args) {
    let [user, setUser] = useState({
        data: [{ money: 0, dongbangtk: 0 }],
        moneyEarn: { moneyEarnToday: 0, moneyEarnPreDay: 0 },
    });

    const [modalOne, setModalOne] = useState(true);

    const toggleOne = () => setModalOne(!modalOne);
    const { t } = useTranslation();
    const [listMission, setListMission] = useState([
        {
            title: `${t('content.home.tvb')}`,
            icon: bacDoan,
            rose: 0.5,
            content: `${t('content.home.bacDoan')}`,
            status: 1,
            level: 'vip6',
        },
        {
            title: `${t('content.home.tvv')}`,
            icon: vangDoan,
            rose: 0.6,
            content: `${t('content.home.vangDoan')}`,
            status: 1,
            level: 'vip5',
        },
        {
            title: `${t('content.home.tvbk')}`,
            icon: bachKim,
            rose: 0.7,
            content: `${t('content.home.backKim')}`,
            status: 0,
            level: 'vip4',
        },
        {
            title: `${t('content.home.tvkc')}`,
            icon: kimCuong,
            rose: 0.8,
            content: `${t('content.home.kimCuong')}`,
            status: 0,
            level: 'vip3',
        },
    ]);

    const [gioithieu, setgioithieu] = useState([
        {
            image: vechungta,
            link: '/ve-chung-toi',
        },
        {
            image: mota,
            link: '/mo-ta-thanh-vien',
        },
        {
            image: taichinh,
            link: '/nguyen-tac-tai-chinh',
        },
        {
            image: vanhoadoanhnghiep,
            link: '/van-hoa-cong-ty',
        },
        {
            image: dieukien,
            link: '/dieu-khoan-su-dung',
        },
        {
            image: mauthuthap,
            link: '/mo-phong-doanh-thu',
        },
        {
            image: phanchianhom,
            link: '/phan-phoi-doi',
        },
        {
            image: chinhsachcanhan,
            link: '/chinh-sach-bao-mat',
        },
    ]);

    const [isHopQua, setIsHopQua] = useState(false);

    const clickKhongDuocQuay = () => {
        toast.error(`${t('content.home.chuaCoLuotQuay')}`, { theme: 'light' });
    };

    const chucNangDangPT = () => {
        toast.error(`${t('content.home.dangPhatTrien')}`, { theme: 'light' });
    };

    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/user-lucky`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    if (data.data[0].may_man && data.data[0].may_man !== '0' && data.data[0].da_quay_may_man === 0) {
                        setIsHopQua(true);
                    } else if (
                        data.data[0].may_man === '0' ||
                        !data.data[0].may_man ||
                        data.data[0].da_quay_may_man === 1
                    ) {
                        setIsHopQua(false);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        checkToken();
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/userInfo`, {
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    // console.log(data);
                    localStorage.setItem('roses_user', data.data[0].roses_user);
                    setUser(data);

                    const updateStatus = (name_level) => {
                        let updatedList = [];
                        switch (name_level) {
                            case 'Thành Viên Bạc':
                                updatedList = listMission.map((mission) =>
                                    mission.title === name_level
                                        ? { ...mission, status: 1 }
                                        : { ...mission, status: 0 },
                                );
                                break;
                            case 'Thành Viên Vàng':
                                updatedList = listMission.map((mission) =>
                                    ['Thành Viên Bạc', 'Thành Viên Vàng'].includes(mission.title)
                                        ? { ...mission, status: 1 }
                                        : { ...mission, status: 0 },
                                );
                                break;
                            case 'Thành Viên Bạch Kim':
                                updatedList = listMission.map((mission) =>
                                    ['Thành Viên Bạc', 'Thành Viên Vàng', 'Thành Viên Bạch Kim'].includes(mission.title)
                                        ? { ...mission, status: 1 }
                                        : { ...mission, status: 0 },
                                );
                                break;
                            case 'Thành Viên Kim Cương':
                                updatedList = listMission.map((mission) => ({ ...mission, status: 1 }));
                                break;
                            default:
                                updatedList = listMission;
                        }
                        setListMission(updatedList);
                    };

                    updateStatus(data.data[0].name_level);
                }
            })
            .catch(function (error) {
                toast.error(`${t('content.error')}`, { theme: 'light' });
            });
    }, []);

    return (
        <div id="Home">
            <div className="top-header-content">
                <div className="lt-header-content">
                    <div className="text-5xl text-black font-bold pb-3">Hello</div>
                    <span className="text-lg text-black">{t('content.home.khoiDau')}`</span>
                </div>
                <div className="title-name-user text-2xl">
                    <div className="name-user-home font-bold pb-6">{user.userBank?.name_u_bank}</div>
                    <div
                        className="money-total"
                        style={{ backgroundColor: '#ff5f3e', borderRadius: '10px', padding: '0px 10px' }}
                    >
                        $ {Number(user.data[0].money).toFixed(2)}
                    </div>
                </div>
                <div className="rt-header-content">
                    <img src={avtIcon} alt="" />
                </div>
            </div>

            <section>
                <Slider />
            </section>

            <div className="imlist mt-2">
                {isHopQua ? (
                    <Link to="/vong-quay-may-man" className="listub w-[72px] pt-[16px]">
                        <img src={hopQua} alt="Hộp quà may mắn" className="w-24" />
                        <span className="text-xl mt-3 text-black text-center">{t('content.home.vongQuayMayMan')}</span>
                    </Link>
                ) : (
                    <div className="listub w-[72px] pt-[16px]" onClick={clickKhongDuocQuay}>
                        <img src={hopQua} alt="Hộp quà may mắn" className="w-24" />
                        <span className="text-xl mt-3 text-black text-center">{t('content.home.vongQuayMayMan')}</span>
                    </div>
                )}

                <div className="listub" onClick={chucNangDangPT}>
                    <img src={viLoiNhuan} alt={t('content.home.viLoiNhuan')} className="w-24" />
                    <span className="text-xl mt-3 text-black text-center">{t('content.home.viLoiNhuan')}</span>
                </div>

                <Link to="/support" className="listub">
                    <img src={napNhanh} alt="Nạp nhanh" className="w-24" />
                    <span className="text-xl mt-3 text-black text-center">{t('content.home.napNhanh')} </span>
                </Link>

                <Link to="/withdraw" className="listub">
                    <img src={rutNhanh} alt="Rút nhanh" className="w-24" />
                    <span className="text-xl mt-3 text-black text-center">{t('content.home.rutNhanh')} </span>
                </Link>
            </div>

            <h2 className="text-3xl mt-16 text-black font-bold">{t('content.home.sanhNhiemVu')}</h2>
            <div className="goodcats">
                {listMission.map((item, i) => {
                    return (
                        <div className="cats" key={i}>
                            <div className="icon-mission z-10">
                                <img src={item.icon} alt="" />
                            </div>
                            <div className="content-mission z-10 pt-8 pl-4">
                                <div className="hoahong text-xl font-bold">
                                    <span>
                                        {t('content.home.hoaHong')}
                                        {item.rose}%
                                    </span>
                                </div>
                                <div className="noidung z-10 text-xl pt-3">{item.content}</div>
                            </div>
                            <Carousel
                                autoPlay={true}
                                showArrows={false}
                                showStatus={false}
                                dynamicHeight={true}
                                emulateTouch={true}
                                infiniteLoop={true}
                                interval={2500}
                                showIndicators={false}
                                showThumbs={false}
                                swipeable={true}
                            >
                                {imgLv[item.level].map((img, i) => {
                                    return (
                                        <div key={i} className="select-none p-2">
                                            <img src={img} alt="banner" className="w-full h-52" />
                                        </div>
                                    );
                                })}
                            </Carousel>

                            {/* {item.status === 1 ? (
                                <Link to="/order/mission" className="central-group-img z-10 w-[90%] pt-4">
                                    <img src={centralGroupImage} alt="" />
                                </Link>
                            ) : (
                                <div className="central-group-img z-10 w-[90%] pt-4 relative">
                                    <img src={centralGroupImage} alt="" />
                                    <div className="bg-khoa w-[100%] h-[90%] flex flex-col items-center justify-center">
                                        <img src={khoa} alt="" />
                                        <span className="text-white text-2xl font-bold">
                                            {t('content.home.choNangCap')}
                                        </span>
                                    </div>
                                </div>
                            )} */}
                            {/* <div className="chu-han">
                                <img src={bgMission} alt="" />
                            </div> */}
                        </div>
                    );
                })}
            </div>

            <div className="ve-chung-ta flex flex-wrap pt-2">
                {gioithieu.map((item, i) => {
                    return (
                        <Link key={i} to={item.link} className="w-[25%] pl-2 pt-3 cursor-pointer">
                            <img src={item.image} alt="" key={i} />
                        </Link>
                    );
                })}
            </div>

            <div className="rounded-[.16rem] my-4">
                <div className="thu-thap-dai-ly">
                    <span>{t('content.home.dongThai')}</span>
                    <span>{t('content.home.them')}</span>
                </div>
                <div className="">
                    <ListHome amount={5} />
                </div>
            </div>

            <div className="bg-modal-home">
                <Modal isOpen={modalOne} toggle={toggleOne} {...args}>
                    <div className="modal-home-style">
                        <ModalHeader toggle={toggleOne}>
                            <div className="image-title flex items-center justify-center pl-[30%]">
                                <img src={modalTitleLeft} alt="" className="w-[35px] h-[15px]" />
                                <img src={thongbaodacbiet} alt="" className="w-[177px] h-[30px] px-4" />
                                <img src={modalTitleRight} alt="" className="w-[35px] h-[15px]" />
                            </div>
                        </ModalHeader>
                        <ModalBody className="text-2xl">
                            <span>{t('content.home.kinhChao')}</span>
                            <br />
                            <br />
                            <span className="leading-10">
                                <span style={{ color: '#3498db' }}>{t('content.home.nangCapDocQuyen')}</span>
                                {t('content.home.nangCapDocQuyen1')}
                            </span>
                            <br />
                            <br />
                            <span className="leading-10">
                                <span style={{ color: '#3498db' }}>{t('content.home.loiIchKhiMoi')}</span>{' '}
                                {t('content.home.loiIch1')}
                            </span>
                            <br />
                            <br />
                            <span className="leading-10">
                                <span style={{ color: '#3498db' }}>{t('content.home.nhacNho')}</span>{' '}
                                {t('content.home.loiIch2')}
                            </span>
                        </ModalBody>
                    </div>
                </Modal>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default Home;
